import React from 'react'
import './Studio_card_one.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import Book_studio_modal from '../../common-section/Modals/book-studio-modal/Book_studio_modal'


const Studio_card_one = () => {

    const [modalShow, setModalShow] = React.useState(false);
    
    return (
        <>
            <section className='Studio_card_one'>
                <div className='container'>
                    <div className='row card-bgg'>
                        {/* <div className=''></div> */}
                        <div className='col-xxl-7 col-lg-6 p-4'>
                            <div className='sec-1'>
                                <h1 className='stud-one'>Studio One <span>S.B. Road, Pune</span></h1>
                                <p className='starp'>4 <FontAwesomeIcon className='staricon' icon={faStar} /></p>

                                <p className='contett'>Bring your own equipment or use our media-made-easy software to set the lighting, audio, teleprompter and camera. This all-inclusive space is perfect for interviews, training videos, promo videos, teleprompted scripts and more! With our system you can quickly change your backdrop, set the
                                    lighting, run the teleprompter and record video at the touch of a button.</p>

                                <div className='d-flex'>
                                    <button className='book-btn' type='button' onClick={() => setModalShow(true)}>Book</button>
                                    <div className='my-auto'>
                                        <p className='startinfp'>Starting from: ₹ 700/Hr</p>
                                    </div>
                                </div>

                                <Book_studio_modal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                />

                            </div>
                        </div>
                        <div className='col-xxl-5 col-lg-6 p-4'>
                            <img className='stud-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/img.png'} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Studio_card_one