import { useState } from "react";
import "../../bookings-form.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

import Accordion from "react-bootstrap/Accordion";
import Success_modal from "../../../common-section/Modals/Success_modal/Success_modal";

const Hourly_form = () => {
  const [modalShow, setModalShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [value, onChange] = useState("10:00");

  const [phoneValue, setPhoneValue] = useState("");

  return (
    <>
      <div className="booking-from-sec">
        <div className="row">
          <div className="col-lg-9 col-md-12 mx-auto">
            <Form>
              <div className="form-bg">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        name="deadline"
                        id="deadline"
                        autoComplete="off"
                        className="form-control"
                        placeholderText="MM/DD/YYYY"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <TimePicker
                        className="form-control"
                        onChange={onChange}
                        value={value}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <TimePicker
                        className="form-control"
                        onChange={onChange}
                        value={value}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <h6 className="estimatedh6">Estimated Booking Cost: ₹ 4,200/-</h6>

              <div className="form-bg">
                <div className="form-group">
                  <Form.Label>Enter Name*</Form.Label>
                  <Form.Control required type="text" placeholder="Enter name" />
                </div>

                <div className="form-group">
                  <Form.Label>Enter Email*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter email"
                  />
                </div>

                <div className="form-group">
                  <PhoneInput
                    className="mb-3"
                    country={"in"}
                    value={phoneValue}
                    onChange={setPhoneValue}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                  />
                </div>

                <div className="form-group">
                  <Form.Label>Enter Project Description*</Form.Label>
                  <Form.Control as="textarea" placeholder="Type here..." />
                </div>
              </div>

              <div className="form-bg mt-3">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Add Ons<span> (Optional)</span>{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="acc-opn mb-3">
                        <div className="add-one">
                          <h6 className="addh6">ADD-ONS</h6>
                          <Form>
                            <Form.Check
                              type="checkbox"
                              label="ADD PROFESSIONAL ASSISTANT + ₹60/Hr"
                            />

                            <Form.Check
                              type="checkbox"
                              label="ADD FULL BODY BACKDROP + ₹75/Hr"
                            />
                          </Form>
                        </div>
                      </div>

                      <div className="acc-opn">
                        <div className="add-one">
                          <h6 className="addh6">ADDITIONAL SUPPORT</h6>
                          <Form>
                            <Form.Check type="checkbox" label="I Need Crew" />

                            <Form.Check
                              type="checkbox"
                              label="I Need Equipment"
                            />

                            <Form.Check
                              type="checkbox"
                              label="I Need An Editor"
                            />

                            <div className="form-group mt-5">
                              <Form.Label className="addlabhh">
                                Add Additional Support
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                placeholder="Type here..."
                              />
                            </div>
                          </Form>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>

              <div className="text-center mt-5">
                <buttton
                  className="proceed-btn"
                  onClick={() => setModalShow(true)}
                >
                  Proceed to Pay
                </buttton>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <Success_modal
        maintext={"Booking Successfully"}
        smalltext={"Soon you will be notified by email & sms."}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Hourly_form;
