import React from 'react'

const MediaBanner = () => {
  return (
    <>
       <section className='About_banner_sec'>
            <div className='container-fluid p-0'>
                <img className='banner-img' src={process.env.PUBLIC_URL +'/assets/images/studio/Banner1.png'} />
                {/* <div className='overlayblack'></div> */}
                <div className='overlay-text'>
                    <h3 className='banner-title'></h3>
                    <h6 className='subtitle'></h6>
                </div>
            </div>
        </section>
    </>
  )
}

export default MediaBanner
