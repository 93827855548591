import React, { useRef, useState } from 'react';
import './why-fns.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const Why_fns = () => {
    return (
        <>
            <section className='Why_fns_sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <img className='fns-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/why-fns/why-fns.png'} />
                        </div>
                        <div className='col-lg-6 ps-md-0 mt-lg-0 mt-5'>
                            <div className='slider-sec'>
                                <h1 className='title'>Why FnS</h1>
                                <h2 className='newand'>New and <br></br>
                                    never-ending possibilities</h2>

                                <div className='swiper-main'>
                                    <Swiper
                                        pagination={{
                                            type: 'fraction',
                                        }}
                                        navigation={true}
                                        modules={[Pagination, 
                                            Navigation
                                        ]}
                                        className="mySwiper"
                                    >
                                        <SwiperSlide>
                                            <div className='slider-div'>
                                                <p className='slider-text'>Find everything from professionally equipped studios
                                                    to unconventional rooms and residences.</p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='slider-div'>
                                                <p className='slider-text'>Find everything from professionally equipped studios
                                                    to unconventional rooms and residences.</p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='slider-div'>
                                                <p className='slider-text'>Find everything from professionally equipped studios
                                                    to unconventional rooms and residences.</p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='slider-div'>
                                                <p className='slider-text'>Find everything from professionally equipped studios
                                                    to unconventional rooms and residences.</p>
                                            </div>
                                        </SwiperSlide>

                                    </Swiper>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='this-make-sec'>
                        <h1 className='newand '>and <br></br>
                            this makes us Special</h1>

                        <div className='row mt-5'>
                            <div className='col-md-6 mb-4'>
                                <div className='special-card'>
                                    <img className='bg-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/why-fns/bg1.png'} />

                                    <div className='overlay-content'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <div className='number-div'>
                                                    <h1 className='number'>01</h1>
                                                </div>
                                            </div>
                                            <div className='col-9 text-end'>
                                                <h1 className='ttt'>Have High Rating</h1>
                                                <p className='subcont'>Lorem ipsum dolor sit amet,
                                                    consectetur adipisicing elit, sed do eiusmod</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-6 mb-4'>
                                <div className='special-card'>
                                    <img className='bg-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/why-fns/bg2.png'} />

                                    <div className='overlay-content'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <div className='number-div'>
                                                    <h1 className='number'>02</h1>
                                                </div>
                                            </div>
                                            <div className='col-9 text-end'>
                                                <h1 className='ttt'>Quiet Hours</h1>
                                                <p className='subcont'>Lorem ipsum dolor sit amet,
                                                    consectetur adipisicing elit, sed do eiusmod</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-6 mb-4'>
                                <div className='special-card'>
                                    <img className='bg-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/why-fns/bg3.png'} />

                                    <div className='overlay-content'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <div className='number-div'>
                                                    <h1 className='number'>03</h1>
                                                </div>
                                            </div>
                                            <div className='col-9 text-end'>
                                                <h1 className='ttt'>Best Location</h1>
                                                <p className='subcont'>Lorem ipsum dolor sit amet,
                                                    consectetur adipisicing elit, sed do eiusmod</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-6 mb-4'>
                                <div className='special-card'>
                                    <img className='bg-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/why-fns/bg4.png'} />

                                    <div className='overlay-content'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <div className='number-div'>
                                                    <h1 className='number'>04</h1>
                                                </div>
                                            </div>
                                            <div className='col-9 text-end'>
                                                <h1 className='ttt'>Cancellation</h1>
                                                <p className='subcont'>Lorem ipsum dolor sit amet,
                                                    consectetur adipisicing elit, sed do eiusmod</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Why_fns