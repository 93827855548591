import React from 'react'
import './meet-team.css'
import { Link } from 'react-router-dom'

const Meet_team = () => {
    return (
        <>
            <section className='Meet_team_section '>
                <div className='container'>
                    <div className='meetteambg'>
                        <div className='row'>
                            <div className='col-xl-7 mx-auto'>
                                <div className='title-div'>
                                    <p className='subt'>MEET OUR</p>
                                    <h1 className='team-title'>Team</h1>
                                    <p className='subt2'>There is no denying fact, that the success lies in reaching out</p>

                                    <div className='bonds-card-main'>
                                        <h1 className='bonds-title'>BONDS</h1>


                                        <div className='colapsediv'>
                                            <div className='row ms-0 me-0'>
                                                <div className='col-md-4 col-sm-4 space1'>
                                                    <div className='teamcard'>
                                                        <img className='person' src={process.env.PUBLIC_URL + '/assets/images/about-us/Meet-team/team1.png'} />
                                                        <div className='black-overlay'></div>
                                                        <div className='overlay-content'>
                                                            <h6 className='name'>Lorem Ipsum</h6>
                                                            <p className='designation'>Designation</p>

                                                            <div className='d-flex'>
                                                                <div><Link><img className='socialiccc' src={process.env.PUBLIC_URL + '/assets/images/about-us/Meet-team/icons/facebook.png'} /></Link></div>

                                                                <div><Link><img className='socialiccc' src={process.env.PUBLIC_URL + '/assets/images/about-us/Meet-team/icons/instagram.png'} /></Link></div>

                                                                <div><Link><img className='socialiccc' src={process.env.PUBLIC_URL + '/assets/images/about-us/Meet-team/icons/twitter.png'} /></Link></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-md-4  col-sm-4 space1 space2 '>
                                                    <div className='teamcard'>
                                                        <img className='person' src={process.env.PUBLIC_URL + '/assets/images/about-us/Meet-team/team2.png'} />
                                                        <bd className="bd1"></bd>
                                                        <bd className="bd2"></bd>
                                                        <div className='black-overlay'></div>
                                                        <div className='overlay-content'>
                                                            <h6 className='name'>Lorem Ipsum</h6>
                                                            <p className='designation'>Designation</p>

                                                            <div className='d-flex'>
                                                                <div><Link><img className='socialiccc' src={process.env.PUBLIC_URL + '/assets/images/about-us/Meet-team/icons/facebook.png'} /></Link></div>

                                                                <div><Link><img className='socialiccc' src={process.env.PUBLIC_URL + '/assets/images/about-us/Meet-team/icons/instagram.png'} /></Link></div>

                                                                <div><Link><img className='socialiccc' src={process.env.PUBLIC_URL + '/assets/images/about-us/Meet-team/icons/twitter.png'} /></Link></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-md-4  col-sm-4 space1'>
                                                    <div className='teamcard'>
                                                        <img className='person' src={process.env.PUBLIC_URL + '/assets/images/about-us/Meet-team/team3.png'} />
                                                        
                                                        <div className='black-overlay'></div>
                                                        <div className='overlay-content'>
                                                            <h6 className='name'>Lorem Ipsum</h6>
                                                            <p className='designation'>Designation</p>

                                                            <div className='d-flex'>
                                                                <div><Link><img className='socialiccc' src={process.env.PUBLIC_URL + '/assets/images/about-us/Meet-team/icons/facebook.png'} /></Link></div>

                                                                <div><Link><img className='socialiccc' src={process.env.PUBLIC_URL + '/assets/images/about-us/Meet-team/icons/instagram.png'} /></Link></div>

                                                                <div><Link><img className='socialiccc' src={process.env.PUBLIC_URL + '/assets/images/about-us/Meet-team/icons/twitter.png'} /></Link></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Meet_team