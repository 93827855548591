import React, { useRef, useState } from 'react';
import './Banner.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination, Autoplay } from 'swiper/modules';

function Banner() {
  return (
    <section className="home-banner">
      <div className="container-fluid p-0">
        <div className="d-flex">
          <div className="sec-1">
            <div className="img-div">
              <img className="ban-img" src={process.env.PUBLIC_URL + '/assets/images/homepage/home-banner/bg1.png'} />
            </div>

            <div className="slider-div">
              <p className='slidemain-t'>Explore a new you</p>
              <Swiper pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper">

                <SwiperSlide>
                  <div className='main-div'>
                    <h1 className='title'>Find a space.</h1>
                    <h1 className='title'>Fulfill your vision.</h1>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className='main-div'>
                    <h1 className='title'>Find a space.</h1>
                    <h1 className='title'>Fulfill your vision.</h1>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className='main-div'>
                    <h1 className='title'>Find a space.</h1>
                    <h1 className='title'>Fulfill your vision.</h1>
                  </div>
                </SwiperSlide>

                <SwiperSlide> 
                  <div className='main-div'>
                    <h1 className='title'>Find a space.</h1>
                    <h1 className='title'>Fulfill your vision.</h1>
                  </div>
                </SwiperSlide>

              </Swiper>
            </div>


          </div>
          <div className="sec-2">
            <img className="ban-img" src={process.env.PUBLIC_URL + '/assets/images/homepage/home-banner/bg2.png'} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
