import React, { useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import './PrivacyPolicy.css'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);
    return (
        <>
            <section className='privacy-policy'>
                <section className='About_banner_sec'>
                    <div className='container-fluid p-0'>
                        <img className='banner-img' src={process.env.PUBLIC_URL + '/assets/images/subscription/PrivacyPolicy.png'} />
                        <div className='overlayblack'></div>
                        <div className='overlay-text'>
                            <h3 className='banner-title'>Privacy Policy</h3>
                            {/* <h6 className='subtitle'>Get Your perfect fit</h6> */}
                        </div>

                        <div className='home-return-pos'>
                            <div className='container'>
                                <Link to="/">Home</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Container>
                    <div className='background-card  med-top'>
                    <img className='curve-img' src={process.env.PUBLIC_URL + '/assets/images/about-us/banner/layer.png'} />
                        <Row>
                            <div className='col-lg-10 mx-auto'>
                                <div className='heading-holder mb-4'>
                                    <p>  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic
                                        typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more
                                        recently with desktop publishing software like Aldu PageMaker including versions of Lorem Ipsum.</p>

                                    <p>  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                        unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic types-
                                        -etting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                                        with desktop publishing software like Aldu PageMaker including versions of Lorem Ipsum.</p>
                                </div>
                                <div className='heading-holder mb-4'>
                                    <h2>1. WHAT INFORMATION WE COLLECT</h2>
                                    <p>  Creating an account at MN is entirely voluntary. Should you choose to create an account with MN, you will be requested to provide certain personally identifiable
                                        information (PII), such as your name, address, phone number, email address, username, password, and company of employment ("Information"). Additionally, we
                                        collect the physical and/or email addresses of your registered Recipients. By providing Information, you confirm that it is accurate & authentic, and that you have
                                        the authorization to share it with MN and our third-party Marketplace sellers involved in fulfilling your orders.</p>

                                    <p>  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                        an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic types-
                                        -etting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                                        with desktop publishing software like Aldu PageMaker including versions of Lorem Ipsum.</p>

                                    <p> Survey Information is handled similarly to other Information. If you reach out to us by phone for customer support, please note that such calls may be recorded
                                        for quality assurance and training purposes.</p>
                                </div>
                                <div className='heading-holder mb-4'>
                                    <h2>2. ACCESS TO & CHOICES ABOUT YOUR INFORMATION</h2>
                                    <p> You have the option to: (1) opt out of receiving promotional materials via mail, phone, or email; (2) access, review, verify, update, or rectify your personal
                                        Information; or (3) deactivate your account. To exercise any of these options, just get in touch with MCW, as detailed herein. You can unsubscribe from
                                        marketing emails by visiting the Communication Preferences page.</p>

                                </div>
                                <div className='heading-holder mb-4'>
                                    <h2>3. SECURITY</h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                                        electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                        and more recently with desktop publishing software like Aldu PageMaker including versions of Lorem Ipsum..</p>

                                    <p>   Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                                        electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                        and more recently with desktop publishing software like Aldu PageMaker including versions of Lorem Ipsum.</p>

                                </div>
                            </div>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default PrivacyPolicy
