import React from 'react'

const About_banner = () => {
  return (
    <>
        <section className='About_banner_sec'>
            <div className='container-fluid p-0'>
                <img className='banner-img' src={process.env.PUBLIC_URL +'/assets/images/about-us/banner/banner.png'} />
                <div className='overlayblack'></div>
                <div className='overlay-text'>
                    <h3 className='banner-title'>Pleasure to Meet You</h3>
                    <h6 className='subtitle'>we help you reach out beyond walls</h6>
                </div>
            </div>
        </section>
    </>
  )
}

export default About_banner