import React from 'react'
import '../../../studio-inner/Breadcrumb-comp/breadcrumb-comp.css'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Daily_breadcrumb = () => {
    return (
        <>
            <div className='container'>
                <div className='Breadcrumb_comp'>
                    <Breadcrumb>
                        <Breadcrumb.Item className='firstbread' href="/">Home  <FontAwesomeIcon icon={faChevronRight} /> </Breadcrumb.Item>
                        <Breadcrumb.Item href="/media-spaces">
                            The Media Studio <FontAwesomeIcon icon={faChevronRight} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item   href="/studio-inner">Studio One <FontAwesomeIcon icon={faChevronRight} /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Daily Booking</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </>
    )
}

export default Daily_breadcrumb