import React, { useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import './Tabs_section.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const Tabs_section = () => {
  return (
    <>
      <section className='StudioTabs_section'>
        <div className='container'>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">


            <Nav variant="pills" className="">
              <Nav.Item>
                <Nav.Link eventKey="first">Features & Amenities</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Charges</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="third">Projects</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="fourth">Terms & Conditions Applied</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="fifth">Reviews</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className='tab-cont'>
                  <h6 className='ttiill'>Studio set-up, Equipment’s, and facilities available are as below:</h6>
                  <ol>
                    <li>Flexible rental options: Hourly, daily, or long-term leases available.
                    </li>
                    <li>High-quality lighting equipment and backdrops.</li>
                    <li>Soundproofing for audio recording and live performances.</li>
                    <li>Comfortable seating and lounge areas for breaks and client meetings.</li>
                    <li>Wi-Fi access and multimedia connectivity.</li>
                    <li>On-site parking and convenient location for easy access.</li>
                  </ol>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="second">
                <div className='tab-cont'>
                  <h6 className='ttiill'>Studio One Charges</h6>
                  <ol>
                    <li>Half Day:  INR. 2,500/- (1st Half: 9:30 ~ 13:30, 2nd Half: 14:30 ~ 18:30)</li>
                    <li>Full Day: INR. 4,800/- (9:30 ~ 18:30)</li>
                    <li>Hourly Basis: INR.700/H (Min 2 Hours Booking Required)</li>
                  </ol>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="third">
                <div className='tab-cont'>
                  <div className='project-swiper'>
                    <Swiper
                      pagination={{
                        dynamicBullets: true,
                      }}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                          <div className='swiper-div'>
                              <div className='row'>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </SwiperSlide>

                      <SwiperSlide>
                          <div className='swiper-div'>
                              <div className='row'>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </SwiperSlide>

                      <SwiperSlide>
                          <div className='swiper-div'>
                              <div className='row'>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </SwiperSlide>

                    </Swiper>
                  </div>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="fourth">
                <div className='tab-cont'>
                  <h6 className='ttiill'>Studio One Terms & Conditions Applied</h6>
                  <ol>
                    <li>For extra hours additional charges applied
                    </li>
                    <li>50% Advance for studio booking, Balance 50% before start of shoot
                    </li>
                    <li>Cancellation charges not applicable for prior 15 days cancellation. If canceled within 8 days or 72 hours before, 50% amount will be non refundable.</li>
                  </ol>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="fifth">
                <div className='tab-cont'>

                  <div className='rating-div'>
                    <h6 className='ttiill'>Pritam Rathod  <span> (4 <FontAwesomeIcon icon={faStar} />) </span></h6>
                    <p className='review'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the
                      1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>

                  <div className='rating-div'>
                    <h6 className='ttiill'>Pritam Rathod  <span> (4 <FontAwesomeIcon icon={faStar} />) </span></h6>
                    <p className='review'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the
                      1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                </div>
              </Tab.Pane>


            </Tab.Content>


          </Tab.Container>
        </div>
      </section>
    </>
  )
}

export default Tabs_section