import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronDown,
  faMagnifyingGlass,
  faShoppingCart,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Col, NavDropdown, Row, Tab } from "react-bootstrap";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from "react-bootstrap/Accordion";

function Header() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    setActiveKey(null);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  const treatments = [
    {
      name: "Dental Fillings",
    },
    {
      name: "Root Canal Treatment",
    },
    {
      name: "Orthodontic Treatment (Invisalign, Aligners, Braces)",
    },
    {
      name: "Dental Crown & Bridges",
    },
    {
      name: "Tooth Extraction / Tooth Removal",
    },
    {
      name: "Pediatrics Dental Treatments",
    },
    {
      name: "Acne & Acne Scar Treatment",
    },
    {
      name: "Tan Removal Treatment",
    },
    {
      name: "Moles Removal",
    },
    {
      name: "Pre - Bridal Treatment",
    },
    {
      name: "Hydra Facial",
    },
  ];

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="main-head-sec">
        <Container>
          <Navbar expand="lg">


            <Navbar.Brand as={Link} to="/">
              <img
                className="headlogo"
                src={
                  process.env.PUBLIC_URL + "/assets/images/logo/logo.png"
                }
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav ">
              <div></div>
              <Nav className="">
                <Nav.Link
                  as={Link}
                  to="/"
                  className={location.pathname === "/" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Home
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/aboutus"
                  className={location.pathname === "/aboutus" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  About Us
                </Nav.Link>

                <NavDropdown  title={<> {" "} Studios {" "}  </>}   
           
                  className={location.pathname === "/media-spaces" ? "active studiodrop" : "studiodrop"}

                id="basic-nav-dropdown">
                  
                  <NavDropdown.Item href="/media-spaces" >
                    <div className="d-flex">
                      <div className="img-div">
                        <img className="menuimg" src={process.env.PUBLIC_URL + '/assets/images/header/item1.png'} />
                      </div>
                      <div className="menunamediv my-auto">
                        <p>Media Space</p>
                      </div>
                    </div>
                  </NavDropdown.Item>

                  <NavDropdown.Item href="/media-spaces">
                    <div className="d-flex">
                      <div className="img-div">
                        <img className="menuimg" src={process.env.PUBLIC_URL + '/assets/images/header/item1.png'} />
                      </div>
                      <div className="menunamediv my-auto">
                        <p>Speak Easy Lounge</p>
                      </div>
                    </div>
                  </NavDropdown.Item>

                  <NavDropdown.Item href="/media-spaces">
                    <div className="d-flex">
                      <div className="img-div">
                        <img className="menuimg" src={process.env.PUBLIC_URL + '/assets/images/header/item1.png'} />
                      </div>
                      <div className="menunamediv my-auto">
                        <p>Podcast Lounge</p>
                      </div>
                    </div>
                  </NavDropdown.Item>

                </NavDropdown>

                <Nav.Link
                  as={Link}
                  to="/subscription"
                  className={location.pathname === "/subscription" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Subscription
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/contact"
                  className={location.pathname === "/contact" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Contact Us
                </Nav.Link>

                <div className="deskviewserchacc">
                  <div className="all-icon">
                    <div className="glassicndiv" onClick={handleShow}>
                      <FontAwesomeIcon icon={faMagnifyingGlass} className="glassicn" />
                    </div>
                    <div className="userdiv">
                      <FontAwesomeIcon icon={faUser} className="usericn" />
                      <NavDropdown title="" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/login">Log In</NavDropdown.Item>
                        <NavDropdown.Item href="/login">Log Out</NavDropdown.Item>
                        <NavDropdown.Item href="/sign-up">Sign Up</NavDropdown.Item>
                        <NavDropdown.Item href="/account">Profile</NavDropdown.Item>
                      </NavDropdown>
                    </div>

                  </div>
                </div>


              </Nav>
            </Navbar.Collapse>

            <div className="mobviewserchacc">
              <div className="all-icon ">
                <div className="glassicndiv" onClick={handleShow}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="glassicn" />
                </div>
                <div className="userdiv">
                  <FontAwesomeIcon icon={faUser} className="usericn" />

                  <NavDropdown title="" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/login">Log In</NavDropdown.Item>
                    <NavDropdown.Item href="/login">Log Out</NavDropdown.Item>
                    <NavDropdown.Item href="/sign-up">Sign Up</NavDropdown.Item>
                    <NavDropdown.Item href="/account">Profile</NavDropdown.Item>
                  </NavDropdown>
                </div>
              </div>
            </div>


          </Navbar>




          {['top'].map((placement, idx) => (
            <Offcanvas className="headsearchoffcanvas" show={show} onHide={handleClose} placement={placement} name={placement}>
              <div className="container">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <h5 className="offtitle">WHAT ARE YOU LOOKING FOR?</h5>

                  <div className="searchdiv">
                    <input className="form-control" type="search" placeholder="Enter your search" />
                  </div>

                  <h5 className="offtitle">OR IS YOUR QUESTION LISTED HERE?</h5>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>How can I change my personal information?</Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <Link to="/faq"><button className="viewallbtn btn" onClick={handleClose}>View All FAQs</button></Link>

                </Offcanvas.Body>
              </div>
            </Offcanvas>
          ))}

        </Container>
      </section>
    </>
  );
}

export default Header;