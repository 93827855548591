import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './book-studio-modal.css'
import { Link } from 'react-router-dom';

const Book_studio_modal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='Book_studio_modal'
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header> */}
                <Modal.Body>                   
                    <img className='mod-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/modal-img.png'}  />

                    <div className='text-center mt-2'>
                        <h5 className='stuh5'>Studio One</h5>
                        <p className='pick-p'>Pick your best</p>

                        <div className='d-flex justify-content-center'>
                            <Link to="/hourly-bookings"><button className='mod-btn'>HOURLY</button></Link>
                            <Link to="/daily-bookings"><button className='mod-btn'>DAILY</button></Link>
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default Book_studio_modal