import React from 'react';
import './add-review-modal.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ReactStars from "react-rating-stars-component";
import Success_modal from '../Success_modal/Success_modal';

const Add_review_modal = (props) => {
    const ratingChanged = (newRating) => {
        console.log(newRating);
    };

    const [successModalShow, setSuccessModalShow] = React.useState(false);

    const handleSubmit = () => {
        setSuccessModalShow(true);
        props.onHide();
    };

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className='Add_review_modal'
                centered
            >
                <Modal.Body>
                    <div className='d-flex ratingflex '>
                        <div className='my-auto'>
                            <p>Rate Studio One:</p>
                        </div>
                        <ReactStars
                            count={5}
                            onChange={ratingChanged}
                            size={20}
                            activeColor="#ffd700"
                        />
                    </div>
                    <h4>Add Review</h4>
                    <Form.Control
                        as="textarea"
                        placeholder="Add your review..."
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancelbtn' onClick={props.onHide}>Cancel</Button>
                    <Button className='submitbtn' onClick={handleSubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>

            <Success_modal
                maintext={"Review Added Successfully"}
                smalltext={""}
                show={successModalShow}
                onHide={() => setSuccessModalShow(false)}
            />
        </>
    );
}

export default Add_review_modal;