import React, { useEffect, useState } from 'react'
import './Account.css'
import { Col, Container, Form, Nav, Row, Tab } from 'react-bootstrap'
import { faCalendarDays, faClock, faEyeSlash, faEye, faFileLines } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { faListCheck, faMagnifyingGlass, faSquareCheck, faTableCellsLarge, faTicket, faWallet } from '@fortawesome/free-solid-svg-icons'
import Account_banner from './account-banner/Account_banner'
import Book_cancel_modal from '../common-section/Modals/book-cancel-modal/Book_cancel_modal'
import Historycard_view_modal from '../common-section/Modals/historycard-view-modal/Historycard_view_modal'
import Success_modal from '../common-section/Modals/Success_modal/Success_modal'
import Add_review_modal from '../common-section/Modals/add-review-modal/Add_review_modal'

function Account() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    const [phoneValue, setPhoneValue] = useState("");

    const [modalShow, setModalShow] = React.useState(false);

    const [modalShow1, setModalShow1] = React.useState(false);

    const [modalShow2, setModalShow2] = useState(false);

    const [modalShow3, setModalShow3] = useState(false);

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };


    const [passwordVisible2, setPasswordVisible2] = useState(false);
    const [confirmPasswordVisible2, setConfirmPasswordVisible2] = useState(false);
    const togglePasswordVisibility2 = () => {
        setPasswordVisible2(!passwordVisible2);
    };
    const toggleConfirmPasswordVisibility2 = () => {
        setConfirmPasswordVisible2(!confirmPasswordVisible2);
    };


    const [passwordVisible3, setPasswordVisible3] = useState(false);
    const [confirmPasswordVisible3, setConfirmPasswordVisible3] = useState(false);
    const togglePasswordVisibility3 = () => {
        setPasswordVisible3(!passwordVisible3);
    };
    const toggleConfirmPasswordVisibility3 = () => {
        setConfirmPasswordVisible3(!confirmPasswordVisible3);
    };

    return (
        <>

            <section className='account_section'>
                <Account_banner />

                <Container>
                    <div className='account_main_div'>


                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                            <Row>
                                <Col xxl={4} xl={4} lg={4} md={12} sm={12} className='mb-4'>
                                    <div className='acctab_div mb-4'>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first"> <FontAwesomeIcon icon={faFileLines} className='me-2' /> Personal Details</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second"> <FontAwesomeIcon icon={faFileLines} className='me-2' /> Bookings</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third"> <FontAwesomeIcon icon={faFileLines} className='me-2' /> History</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="fourth"> <FontAwesomeIcon icon={faFileLines} className='me-2' /> Subscription</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="fiveth"> <FontAwesomeIcon icon={faWallet} className='me-2' /> Payment Method</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                    <div className='acctab_div'>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="second">
                                                <p className='sorttext'>Sort by</p>
                                                <div className='shorttabss py-2'>
                                                    <Form>
                                                        <div className="checcckboxx">
                                                            <Form.Check
                                                                type="radio"
                                                                label="All"
                                                                name="timeframe"
                                                                id="all"
                                                            />
                                                        </div>
                                                        <div className="checcckboxx">
                                                            <Form.Check
                                                                type="radio"
                                                                label="Daily"
                                                                name="timeframe"
                                                                id="daily"
                                                            />
                                                        </div>
                                                        <div className="checcckboxx">
                                                            <Form.Check
                                                                type="radio"
                                                                label="Hourly"
                                                                name="timeframe"
                                                                id="hourly"
                                                            />
                                                        </div>
                                                    </Form>
                                                </div>


                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <p className='sorttext'>Sort by</p>
                                                <div className='shorttabss py-2'>
                                                    <Form>
                                                        <div className="checcckboxx">
                                                            <Form.Check
                                                                type="radio"
                                                                label="All"
                                                                name="timeframe"
                                                                id="all"
                                                            />
                                                        </div>
                                                        <div className="checcckboxx">
                                                            <Form.Check
                                                                type="radio"
                                                                label="Completed"
                                                                name="timeframe"
                                                                id="daily"
                                                            />
                                                        </div>
                                                        <div className="checcckboxx">
                                                            <Form.Check
                                                                type="radio"
                                                                label="Completed"
                                                                name="timeframe"
                                                                id="hourly"
                                                            />
                                                        </div>
                                                    </Form>
                                                </div>


                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={12} className='mb-4'>
                                    <div className='acctabcont_div'>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <div className='tab1_div'>

                                                    <Form className='row'>
                                                        <Form.Group className="mb-3 col-md-6 col-sm-6 col-12" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>First Name<span>*</span></Form.Label>
                                                            <Form.Control type="text" placeholder="Enter your first name" />
                                                        </Form.Group>

                                                        <Form.Group className="mb-3 col-md-6 col-sm-6 col-12" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Last Name<span>*</span></Form.Label>
                                                            <Form.Control type="text" placeholder="Enter your last name" />
                                                        </Form.Group>

                                                        <Form.Group className="mb-3 col-12" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Display Name<span>*</span></Form.Label>
                                                            <Form.Control type="text" placeholder="name@example.com" />
                                                            <p className='displa-instru'>This will be how your name will be displayed in the account section and in reviews</p>
                                                        </Form.Group>

                                                        <div>
                                                            <Form.Label>Contact<span>*</span></Form.Label>
                                                            <PhoneInput
                                                                className="mb-3"
                                                                country={"in"}
                                                                value={phoneValue}
                                                                placeholder='Enter your mobile number'
                                                                onChange={setPhoneValue}
                                                                inputProps={{
                                                                    name: "phone",
                                                                    required: true,
                                                                    autoFocus: true,
                                                                }}
                                                            />
                                                        </div>

                                                        <Form.Group className="mb-3 col-12" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Email address<span>*</span></Form.Label>
                                                            <Form.Control type="email" placeholder="Enter your email address" />
                                                        </Form.Group>

                                                        <Form.Group className="mb-3 col-12" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Current Password<span className='smalllabel'> (leave blank to leave unchanged)</span></Form.Label>
                                                            <div className='eyediv'>
                                                                <Form.Control type={
                                                                    confirmPasswordVisible
                                                                        ? "text"
                                                                        : "password"
                                                                }
                                                                    placeholder="Enter your current password" />


                                                                <div
                                                                    className="eye-icon-holder"
                                                                    onClick={togglePasswordVisibility}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        className="eyeicn"
                                                                        icon={
                                                                            passwordVisible ? faEye : faEyeSlash
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Form.Group>

                                                        <Form.Group className="mb-3 col-12" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>New Password <span className='smalllabel'> (leave blank to leave unchanged)</span></Form.Label>
                                                            <div className='eyediv'>
                                                                <Form.Control type="password" placeholder="Enter your new password" />
                                                                <div
                                                                    className="eye-icon-holder"
                                                                    onClick={togglePasswordVisibility2}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        className="eyeicn"
                                                                        icon={
                                                                            passwordVisible2 ? faEye : faEyeSlash
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Form.Group>

                                                        <Form.Group className="mb-3 col-12" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Confirm Password</Form.Label>
                                                            <div className='eyediv'>
                                                                <Form.Control type="password" placeholder="Enter your confirm password" />
                                                                <div
                                                                    className="eye-icon-holder"
                                                                    onClick={togglePasswordVisibility3}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        className="eyeicn"
                                                                        icon={
                                                                            passwordVisible3 ? faEye : faEyeSlash
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Form.Group>

                                                        <div>
                                                            <button className='btnnsub mt-3' type='button' onClick={() => setModalShow2(true)} >SAVE CHANGES</button>
                                                        </div>
                                                    </Form>
                                                </div>

                                            </Tab.Pane>

                                            <Tab.Pane eventKey="second">
                                                <div>
                                                    <div className='bookiingtab_div'>
                                                        <Row>
                                                            <Col xxl={3} xl={3} lg={4} md={4} sm={4} className='pe-sm-0'>
                                                                <div className='imgsidemain'>
                                                                    <div className=' imggsssdiv'>
                                                                        <img
                                                                            className="imgg"
                                                                            src={
                                                                                process.env.PUBLIC_URL + "/assets/images/logo/logo.png"
                                                                            }
                                                                            alt="Logo"
                                                                        />

                                                                    </div>
                                                                    <div className='imggtxxxt pt-2'>
                                                                        <p className='bookprice mb-0'>Booking: ₹ 4,200/-</p>
                                                                        <p className='adrtext'>(Starting from: ₹ 700/Hr)</p>
                                                                        <p className='adrtext mb-0' onClick={() => setModalShow(true)} type="button"> <FontAwesomeIcon icon={faCalendarDays} />CANCEL</p>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col xxl={9} xl={9} lg={8} md={8} sm={8} className='ps-sm-0'>
                                                                <div className='booktext_div'>

                                                                    <p className='stdtext mb-1'>Studio One</p>
                                                                    <p className='adrtext mb-1'>S.B. Road, Pune</p>
                                                                    <p className='timee time2 mb-1'> <FontAwesomeIcon icon={faTicket} className='me-1' /> Booking: Daily</p>
                                                                    <div className='d-flex justify-content-between flex-wrap'>
                                                                        <p className='timee '><FontAwesomeIcon icon={faCalendarDays} /> <span className='datespan me-1 p-1'>30.JUN.2024 </span> to  <span className='datespan ms-1 p-1'> 20.JUL.2024 </span></p>
                                                                        <p className='timee '><FontAwesomeIcon icon={faClock} /> <span className='datespan me-1 p-1'> 10:00am </span> to <span className='datespan ms-1 p-1'> 04:00pm </span></p>
                                                                    </div>

                                                                    <p className='timee time2 mb-1'> <FontAwesomeIcon icon={faTableCellsLarge} className='me-1' /> Add Ons:</p>
                                                                    <p className='adrtext mb-1 ps-3 ms-1'>Professional Assistant +₹60/hr</p>
                                                                    <p className='adrtext mb-1 ps-3 ms-1'>Crew</p>
                                                                    <p className='timee time2 mb-1'> <FontAwesomeIcon icon={faListCheck} className='me-1' /> Description:</p>
                                                                    <div className='d-flex justify-content-between flex-wrap'>
                                                                        <p className='adrtext mb-0 ps-3 ms-1'>Lorem Ipsum is simply dummy text of the printing & typesetting...</p>
                                                                        <p className='timee time2  text-decoration-underline mb-0'>View More</p>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div className='bookiingtab_div'>
                                                        <Row>
                                                            <Col xxl={3} xl={3} lg={4} md={4} sm={4} className='pe-sm-0'>
                                                                <div className='imgsidemain'>
                                                                    <div className=' imggsssdiv'>
                                                                        <img
                                                                            className="imgg"
                                                                            src={
                                                                                process.env.PUBLIC_URL + "/assets/images/logo/logo.png"
                                                                            }
                                                                            alt="Logo"
                                                                        />

                                                                    </div>
                                                                    <div className='imggtxxxt pt-2'>
                                                                        <p className='bookprice mb-0'>Booking: ₹ 4,200/-</p>
                                                                        <p className='adrtext'>(Starting from: ₹ 700/Hr)</p>
                                                                        <p className='adrtext mb-0' onClick={() => setModalShow(true)} type="button"> <FontAwesomeIcon icon={faCalendarDays} />CANCEL</p>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col xxl={9} xl={9} lg={8} md={8} sm={8} className='ps-sm-0'>
                                                                <div className='booktext_div'>

                                                                    <p className='stdtext mb-1'>Studio One</p>
                                                                    <p className='adrtext mb-1'>S.B. Road, Pune</p>
                                                                    <p className='timee time2 mb-1'> <FontAwesomeIcon icon={faTicket} className='me-1' /> Booking: Daily</p>
                                                                    <div className='d-flex justify-content-between flex-wrap'>
                                                                        <p className='timee '><FontAwesomeIcon icon={faCalendarDays} /> <span className='datespan me-1 p-1'>30.JUN.2024 </span> to  <span className='datespan ms-1 p-1'> 20.JUL.2024 </span></p>
                                                                        <p className='timee '><FontAwesomeIcon icon={faClock} /> <span className='datespan me-1 p-1'> 10:00am </span> to <span className='datespan ms-1 p-1'> 04:00pm </span></p>
                                                                    </div>

                                                                    <p className='timee time2 mb-1'> <FontAwesomeIcon icon={faTableCellsLarge} className='me-1' /> Add Ons:</p>
                                                                    <p className='adrtext mb-1 ps-3 ms-1'>Professional Assistant +₹60/hr</p>
                                                                    <p className='adrtext mb-1 ps-3 ms-1'>Crew</p>
                                                                    <p className='timee time2 mb-1'> <FontAwesomeIcon icon={faListCheck} className='me-1' /> Description:</p>
                                                                    <div className='d-flex justify-content-between flex-wrap'>
                                                                        <p className='adrtext mb-0 ps-3 ms-1'>Lorem Ipsum is simply dummy text of the printing & typesetting...</p>
                                                                        <p className='timee time2  text-decoration-underline mb-0'>View More</p>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                    </div>

                                                </div>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="third">

                                                <div className='tab2div'>
                                                    <Form.Group className="mb-5 col-12" controlId="exampleForm.ControlInput1">

                                                        <div className='eyediv'>
                                                            <Form.Control type="password" placeholder="Search by Date / Project / Studio Name" />
                                                            <FontAwesomeIcon icon={faMagnifyingGlass} className='eyeicn2' />

                                                        </div>
                                                    </Form.Group>

                                                    <div className='tabbcard_div'>
                                                        <div className='crdd1_div'>
                                                            <div className='imgrowdivv'>
                                                                <div className=' imggdiv'>
                                                                    <img
                                                                        className="imgg"
                                                                        src={
                                                                            process.env.PUBLIC_URL + "/assets/images/logo/logo.png"
                                                                        }
                                                                        alt="Logo"
                                                                    />

                                                                </div>
                                                                <div className='bookd_div'>
                                                                    <p className='booktext'>Booked on: 20.JUN.2024</p>
                                                                </div>
                                                            </div>
                                                            <div className=' texttdiv'>
                                                                <p className='stdtext mb-1'>Studio One</p>
                                                                <p className='adrtext mb-1'>S.B. Road, Pune</p>
                                                                <p className='timee time2 mb-1'>Booking: Daily (₹ 4,200/-)</p>
                                                                <div className='d-flex justify-content-between'>
                                                                    <p className='timee mb-1'><FontAwesomeIcon icon={faCalendarDays} /> 30.JUN.2024 {' '}{' '} to {' '}{' '} 20.JUL.2024</p>
                                                                    <p className='timee mb-1'><FontAwesomeIcon icon={faClock} /> 10:00 am {' '}{' '} to {' '}{' '} 04:00 pm</p>
                                                                </div>
                                                                <div className='d-flex justify-content-between'>

                                                                    <p className='timee time2 mb-0' onClick={() => setModalShow3(true)}>Add Ratings & Review</p>

                                                                    <p className='timee time2  text-decoration-underline mb-0' onClick={() => setModalShow1(true)}>View More</p>
                                                                </div>

                                                            </div>



                                                        </div>
                                                        <div className=' complettext'>
                                                            <p>Completed</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Tab.Pane>

                                            <Tab.Pane eventKey="fourth">
                                                <div>
                                                    <div className='bookiingtab_div'>
                                                        <Row>
                                                            <Col xxl={3} xl={3} lg={4} md={4} sm={4} xs={4} className='pe-0'>
                                                                <div className='imgsidemain forrdivv'>
                                                                    <div className=' imggsssdiv'>
                                                                        <img
                                                                            className="imgg"
                                                                            src={
                                                                                process.env.PUBLIC_URL + "/assets/images/logo/logo.png"
                                                                            }
                                                                            alt="Logo"
                                                                        />

                                                                    </div>

                                                                </div>

                                                            </Col>
                                                            <Col xxl={9} xl={9} lg={8} md={8} sm={8} xs={8} className='ps-0'>
                                                                <div className='booktext_div subscrtabdiv'>

                                                                    <p className='stdtext mb-1'>Starter Plan (₹99)</p>
                                                                    <p className='adrtext  mb-1'> <FontAwesomeIcon icon={faSquareCheck} className='squrecheckiccn me-1' /> Lorem Ipsum Dolor Sit Amet</p>
                                                                    <p className='adrtext  mb-1'> <FontAwesomeIcon icon={faSquareCheck} className='squrecheckiccn me-1' /> Lorem Ipsum Dolor Sit Amet</p>
                                                                    <p className='adrtext  mb-1'> <FontAwesomeIcon icon={faSquareCheck} className='squrecheckiccn me-1' /> Lorem Ipsum Dolor Sit Amet</p>
                                                                    <p className='adrtext  mb-1'> <FontAwesomeIcon icon={faSquareCheck} className='squrecheckiccn me-1' /> Lorem Ipsum Dolor Sit Amet</p>

                                                                    <div className=' update_div'>
                                                                        <p>Update</p>
                                                                    </div>
                                                                    <div className='monthly_div'>
                                                                        <h3 className='monthly_p'>Monthly</h3>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                        </Row>

                                                        <p className='validuptoo'>Valid Till 27.JUN.2024</p>
                                                    </div>





                                                    <div className='bookiingtab_div'>
                                                        <Row>
                                                            <Col xxl={3} xl={3} lg={4} md={4} sm={4} xs={4} className='pe-0'>
                                                                <div className='imgsidemain forrdivv'>
                                                                    <div className=' imggsssdiv'>
                                                                        <img
                                                                            className="imgg"
                                                                            src={
                                                                                process.env.PUBLIC_URL + "/assets/images/logo/logo.png"
                                                                            }
                                                                            alt="Logo"
                                                                        />

                                                                    </div>

                                                                </div>

                                                            </Col>
                                                            <Col xxl={9} xl={9} lg={8} md={8} sm={8} xs={8} className='ps-0'>
                                                                <div className='booktext_div subscrtabdiv subscrtabdiv2'>

                                                                    <p className='stdtext mb-1'>Starter Plan (₹99)</p>
                                                                    <p className='adrtext  mb-1'> <FontAwesomeIcon icon={faSquareCheck} className='squrecheckiccn me-1' /> Lorem Ipsum Dolor Sit Amet</p>
                                                                    <p className='adrtext  mb-1'> <FontAwesomeIcon icon={faSquareCheck} className='squrecheckiccn me-1' /> Lorem Ipsum Dolor Sit Amet</p>
                                                                    <p className='adrtext  mb-1'> <FontAwesomeIcon icon={faSquareCheck} className='squrecheckiccn me-1' /> Lorem Ipsum Dolor Sit Amet</p>
                                                                    <p className='adrtext  mb-1'> <FontAwesomeIcon icon={faSquareCheck} className='squrecheckiccn me-1' /> Lorem Ipsum Dolor Sit Amet</p>

                                                                    <div className=' update_div renediv'>
                                                                        <p>Renew</p>
                                                                    </div>
                                                                    <div className='monthly_div'>
                                                                        <h3 className='monthly_p ' style={{ margin: "0px 0px 0px 0px" }}> YEARLY &nbsp; &nbsp; </h3>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                        </Row>

                                                        <p className='validuptoo'>Valid Till 27.JUN.2024</p>
                                                    </div>
                                                </div>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="fiveth">
                                                <p className='no-saved-pay'>No saved payment methods found</p>
                                            </Tab.Pane>

                                        </Tab.Content>
                                    </div>
                                </Col>
                            </Row>
                        </Tab.Container>

                    </div>
                </Container>


                <Book_cancel_modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />

                <Historycard_view_modal
                    show={modalShow1}
                    onHide={() => setModalShow1(false)}
                />


                <Success_modal
                    maintext={"Changes Saved Successfully"}
                    smalltext={""}
                    show={modalShow2}
                    onHide={() => setModalShow2(false)}
                />


                <Add_review_modal
                    show={modalShow3}
                    onHide={() => setModalShow3(false)}
                />


            </section>
        </>
    )
}

export default Account