import { useEffect, useState } from "react";
import './loginform.css'
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faHouse } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const Forgot_password = () => {

    const [phoneValue, setPhoneValue] = useState("");

    return (
        <>
            <section className='login-page-main-sec'>
                <div className='container'>
                    <div className='main-position-div'>
                        <div className='d-flex aligndiv'>
                            

                            <div className='mid-img-div'>
                                <img src={process.env.PUBLIC_URL + '/assets/images/login/mid-img2.png'} />
                            </div>

                            <div className='last-div slidein margin-top'>
                                <div className='loginformsec topspace'>
                                    <Link to="/login"><FontAwesomeIcon className='home-icn' icon={faChevronLeft} /></Link>
                                    <h1 className='login-title'>Reset Your Password</h1>
                                    <p className='loginby'>You will receive an email from us requesting you to reset your password.</p>

                                    <div className='form-div '>
                                        <Form >
                                            <Form.Group className='form-group resetpassformtop'>
                                                <Form.Label>Email or Mobile Number</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Email or Mobile Number" />
                                            </Form.Group>
                                            
                                            <Link to="/set-password"><button className='Login-btn resetpasstop'>Submit</button></Link>
                                        </Form>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Forgot_password