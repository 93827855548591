import React, { useState } from 'react'
import './FilterModal.css'
import { Button, Col, Nav, Row, Tab } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const FilterModal = (props) => {

    const [value, setValue] = useState({ min: 0, max: 100 }); // Initial range values
    const minValue = 0;
    const maxValue = 100;

    const [isOpen, setIsOpen] = useState(false);

    const toggleFilter = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='filter-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                       
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col lg={3} md={4} className='pe-lg-0 pe-md-0 pe-sm-unset pe-unset'>
                                <div className='bg-color'>
                                    <div className='heading-name mb-4'>
                                        <h2>Sort By</h2>
                                    </div>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Location</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second" className='border-line'>Pricing</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third" className='border-line'>Ratings</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </Col>
                            <Col lg={9} md={8} className='ps-lg-0 ps-md-0 ps-sm-unset ps-unset '>

                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className='location-bg'>
                                            <Row>
                                                <Col lg={5}>
                                                    <Form>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Desired Location</Form.Label>
                                                            <Form.Control type="email" placeholder="Enter your desired location" />
                                                        </Form.Group>
                                                        <Button className='btn-search'>SEARCH</Button>
                                                    </Form>
                                                </Col>
                                                <Col lg={2}>
                                                    <div className='circle-orr text-center'>
                                                        <p>OR</p>
                                                    </div>
                                                </Col>
                                                <Col lg={5}>
                                                    <div className='distance-range'>
                                                        <p>Select Distance Range (kms)</p>
                                                        <div className="range-bar ">
                                                            <InputRange
                                                                draggableTrack
                                                                minValue={minValue}
                                                                maxValue={maxValue}
                                                                value={value}
                                                                onChange={(newValue) => setValue(newValue)}
                                                            />
                                                            <div className="slider-value">
                                                                <div className="slide">
                                                                    <span className="text">{minValue}</span>
                                                                    <span className="text">{maxValue}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className='location-bg-second'>
                                            <Row>
                                                <Col lg={5}>
                                                    <Form>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Desired Budget</Form.Label>
                                                            <Form.Control type="email" placeholder="Enter your desired budget" />
                                                        </Form.Group>
                                                        <Button className='btn-search'>SEARCH</Button>
                                                    </Form>
                                                </Col>
                                                <Col lg={2}>
                                                    <div className='circle-orr text-center'>
                                                        <p>OR</p>
                                                    </div>
                                                </Col>
                                                <Col lg={5}>
                                                    <div className='distance-range'>
                                                        <p>Select Budget Range</p>
                                                        <div className="range-bar ">
                                                            <InputRange
                                                                draggableTrack
                                                                minValue={minValue}
                                                                maxValue={maxValue}
                                                                value={value}
                                                                onChange={(newValue) => setValue(newValue)}
                                                            />
                                                        </div>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <div className='location-bg-third'>
                                            <Form>
                                                <Row className="mb-3">
                                                    <p>Select Ratings</p>
                                                    <Form.Group as={Col} lg={4} className="mb-3 d-flex align-items-center" id="formGridCheckbox1">
                                                        <div className="d-flex align-items-center">
                                                            <Form.Check type="checkbox" name="group1" label="Ratings 3.5+" className="me-2" />
                                                            <FontAwesomeIcon icon={faStar} className='star-icon' />
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={4} className="mb-3 d-flex align-items-center" id="formGridCheckbox2">
                                                        <div className="d-flex align-items-center">
                                                            <Form.Check type="checkbox" name="group1" label="Ratings 4.0+" className="me-2" />
                                                            <FontAwesomeIcon icon={faStar} className='star-icon' />
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={4} className="mb-3 d-flex align-items-center" id="formGridCheckbox3">
                                                        <div className="d-flex align-items-center">
                                                            <Form.Check type="checkbox" name="group1" label="Ratings 4.5+" className="me-2" />
                                                            <FontAwesomeIcon icon={faStar} className='star-icon' />


                                                            
                                                        </div>
                                                    </Form.Group>
                                                </Row>
                                                <Button className='btn-search'>SEARCH</Button>
                                            </Form>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>

                    </Tab.Container>

                </Modal.Body>

            </Modal>
        </>
    )
}

export default FilterModal
