import React from 'react'
import './home-about-us.css'
import { Link } from 'react-router-dom'

const Home_about_us = () => {
    return (
        <>
            <div className='container'>
                <section className='Home_about_us_section'>
                    <div className='row'>
                        <div className='col-xxl-8 col-xl-7 col-lg-7 mx-auto'>
                            <div className='heading-text-div'>
                                <h1 className='about-text'>About</h1>
                                <p className='middle-content'>At FlicknShoot, we offer top-of-the-line studio spaces for rent, tailored to meet the needs of creative individuals,
                                    content creators, photographers, videographers, and artists alike. Whether you're working on a
                                    photography project, filming a video, hosting a workshop, or conducting a rehearsal,
                                    our versatile studios provide the perfect environment to bring your vision
                                    to life.</p>
                                <h1 className='us-text'>Us</h1>
                                <Link to="/aboutus" className='readmore'>READ MORE</Link>
                            </div>
                        </div>
                    </div>

                    <div className='overlay-images'>
                        <div className='leftdiv'>
                            <img className='leftside' src={process.env.PUBLIC_URL + '/assets/images/homepage/aboutus/bg1.png'} />
                        </div>

                        <div className='righttdiv'>
                            <img className='rightside' src={process.env.PUBLIC_URL + '/assets/images/homepage/aboutus/bg2.png'} />
                        </div>
                    </div>
                </section>
            </div >
        </>
    )
}

export default Home_about_us