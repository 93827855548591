import React from 'react'
import './Our-mission.css'
import Counter from '../../home/counter/Counter'

const Our_mission = () => {
    return (
        <>
            <section className='Our_mission_sec'>
                <div className='container'>
                    <div className='background'>
                        <div className='align-div text-center mb-5'>
                            <h1 className='mision-title'>Our Mission & Vision</h1>
                        </div>

                        <div className='row mission-main '>
                            <div className='col-md-6 mb-5 order-md-1'>
                                <p className='text'>Fostering innovation with every studio rental, shaping the future of
                                    media creation.</p>
                            </div>

                            <div className='col-md-6 mb-5 order-md-2'>
                                <div className='d-flex'>
                                    <div className='icon-div'>
                                        <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/about-us/our-mission/mission.png'} />
                                    </div>
                                    <div className='content-div'>
                                        <h5 className='title'>Mission</h5>
                                        <p className='text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-6 mb-5 order-md-3 order-1'>
                                <div className='feedback-div'>
                                    <div className='d-flex'>
                                        <img className='per' src={process.env.PUBLIC_URL + '/assets/images/about-us/our-mission/person.png'} />
                                        <img className='per' src={process.env.PUBLIC_URL + '/assets/images/about-us/our-mission/person.png'} />
                                        <img className='per' src={process.env.PUBLIC_URL + '/assets/images/about-us/our-mission/person.png'} />
                                        <img className='per' src={process.env.PUBLIC_URL + '/assets/images/about-us/our-mission/person.png'} />
                                        <img className='per' src={process.env.PUBLIC_URL + '/assets/images/about-us/our-mission/person.png'} />
                                    </div>

                                    <h6 className='count'>3000K<span>+</span></h6>
                                    <p className='posi-fed'>Positve Feedbacks</p>
                                </div>
                            </div>

                            <div className='col-md-6 mb-5 order-md-4 '>
                                <div className='d-flex'>
                                    <div className='icon-div'>
                                        <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/about-us/our-mission/Icon.png'} />
                                    </div>
                                    <div className='content-div'>
                                        <h5 className='title'>Vision</h5>
                                        <p className='text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className='pronounced-sec text-center'>
                            <h1 className='title pb-4'>Pronouned to Empower </h1>
                            <Counter />
                        </div>
                    </div>
                </div>


            </section>
        </>
    )
}

export default Our_mission