import React from 'react'
import './home-counter.css'

import CountUp from 'react-countup';

const Counter = () => {
    return (
        <>
            <section className='Counter_sec'>
                <div className='container'>
                    <div className='counter-bg-div'>
                        <div className='row'>
                            <div className='col-md-3 col-6  mb-3 aligndiv'>
                                {/* <h1 className='count-text'>1.5<span>K</span></h1> */}
                                <div>
                                    <div> <h1 ><CountUp className='count-text' delay={2} end={2} /><span className="spank">K</span></h1></div>
                                    <div><p className='subtext'>Happy Clients</p></div>
                                </div>
                            </div>
                            <div className='col-md-3 col-6  mb-3 aligndiv'>
                                {/* <h1 className='count-text'>2.9<span>K</span></h1> */}
                                <div>
                                    <div><h1 ><CountUp className='count-text' delay={2} end={3} /><span className="spank">K</span></h1></div>
                                    <div><p className='subtext'>Successful Projects</p></div>
                                </div>
                            </div>
                            <div className='col-md-3 col-6   aligndiv'>
                                {/* <h1 className='count-text'>20<span>+</span></h1> */}
                                <div>
                                    <div><h1 ><CountUp className='count-text' delay={2} end={20} /><span className="spank">+</span></h1></div>
                                    <div><p className='subtext'>Dynamic Services</p></div>
                                </div>
                            </div>
                            <div className='col-md-3 col-6  aligndiv'>
                                {/* <h1 className='count-text'>9<span>+</span></h1> */}
                                <div>
                                    <div><h1 ><CountUp className='count-text' delay={2} end={9} /><span className="spank">+</span></h1></div>
                                    <div> <p className='subtext'>Delighting Years</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Counter