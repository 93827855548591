import React, { useEffect } from 'react'
import '../bookings-form.css'
import Daily_breadcrumb from '../daily-bookings/breadcrumb/Daily_breadcrumb'
import Daily_form from '../daily-bookings/daily-form/Daily_form'

const Daily_bookings = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <div className='Hourly_bookings_topbg'>
        <div className='container'>
          <h1 className='make-yourh1'>Make Your Plans a Reality</h1>
          <Daily_breadcrumb />
          <Daily_form />
        </div>
      </div>
    </>
  )
}

export default Daily_bookings