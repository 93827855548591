import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { faPhone, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faHeart,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { faFacebookF, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";


const Footer = () => {
  const lnks = [
    {
      name: "Home",
      BaseLink: "/",
    },
    {
      name: "About Us",
      BaseLink: "/aboutus",
    },
    {
      name: "Subscription",
      BaseLink: "/subscription",
    },
    {
      name: "Contact Us",
      BaseLink: "/contact",

    },
    {
      name: "Terms & Conditions",
      BaseLink: "/terms-condition",
    },
    {
      name: "Privacy Policy",
      BaseLink: "/privacy-policy",
    },
    {
      name: "FAQs",
      BaseLink: "/faq",
    },
  ];
  return (
    <>
      <section className="footer_div">
        <Container>
          <Row >
            <Col xxl={8}
              xl={9}
              lg={10}
              md={11}
              sm={11}
              xs={12}
              className="mx-auto">

              <div className="footermain_div">
                <div className="footer_top">

                  <div className="d-flex justify-content-around flex-wrap">
                    {lnks.map((text, index) => (

                      <div className="top_div_footer  ">
                        <Link to={text.BaseLink}>
                          <p className="top_text px-2">{text.name}</p>
                        </Link>
                      </div>

                    ))}
                  </div>
                </div>
                
                <div className="footer_miidle">
                  <Row>
                    <Col
                      xxl={8}
                      xl={8}
                      lg={7}
                      md={8}
                      sm={10}
                      xs={12}
                      className="mx-auto"
                    >
                      <div className="top_div_footer">
                        <div className="addres_div padding_div">
                          <p className="top_text">
                            2nd Floor, Office No 3, M No. 734, Opp High Class
                            Society, Pashan Rd, near Suryadutta College, Patil
                            Nagar, Bavdhan, Pune, Maharashtra 411021
                          </p>
                        </div>
                        <div className="contact_div padding_div">
                          <p className="top_text">
                            <FontAwesomeIcon icon={faEnvelope}  className="footericn" />
                            {" "}
                           support@flicknshoot.com
                          </p>
                          <p className="top_text">
                            <FontAwesomeIcon icon={faPhone} className="footericn" />{" "}
                            +91 1000890984
                          </p>
                        </div>

                        <div className="all_icon padding_div">
                          {/* <FontAwesomeIcon icon={faFacebookF} className="cart-logo" /> */}
                          {/* <FontAwesomeIcon icon={faFacebook} /> */}
                          <Link to={"https://www.facebook.com/"} target="blank">
                            <div className="headericn_div">

                              <FontAwesomeIcon className="cart_logo" icon={faFacebookF} />

                            </div>
                          </Link>

                          <Link to={"https://www.instagram.com/"} target="blank">
                            <div className="headericn_div">
                              {/* <FontAwesomeIcon
                            icon={faHeart}
                            className="cart_logo"
                          /> */}
                              <FontAwesomeIcon icon={faInstagram} className="cart_logo" />
                            </div>
                          </Link>
                          <Link to={"https://www.youtube.com/"} target="blank">
                            <div className="headericn_div">
                              {/* <FontAwesomeIcon
                            icon={faUser}
                            className="cart_logo"
                          /> */}
                              <FontAwesomeIcon icon={faYoutube} className="cart_logo" />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <p className="bottom_footer_text">
                  © 2024 Flicknshoot. All Rights Reserved. Developed by <Link  target="_blank" className="bottom_footer_text" to="https://profcymaglobal.com">Profcyma</Link>
                </p>
              </div>
            </Col>

          </Row>
        </Container>

        <div className="big-title">
          <h1>FlickNShoot</h1>
        </div>
      </section>
    </>
  );
};

export default Footer;
