import React, { useEffect } from 'react'
import '../bookings-form.css'
import Hourly_breadcrumb from './breadcrumb/Hourly_breadcrumb'
import Hourly_form from './hourly-form/Hourly_form'

const Hourly_bookings = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);
    return (
        <>
            <div className='Hourly_bookings_topbg'>
                <div className='container'>
                    <h1 className='make-yourh1'>Make Your Plans a Reality</h1>
                    <Hourly_breadcrumb />
                    <Hourly_form />

                </div>
            </div>
        </>
    )
}

export default Hourly_bookings