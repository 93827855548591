import React from 'react';
import './MediaCardSec.css';
import { Container, Row, Col, Nav, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import StudioMediaCard from './StudioMediaCard/StudioMediaCard';
import { Link } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import FilterModal from './FilterModal/FilterModal';
const MediaCardSec = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const MediacardsDetails = [
    {
      id: 1,
      Title: "Studio One",
      LocationText: "S.B. Road, Pune",
      numberRate: "4",
      image: process.env.PUBLIC_URL + "/assets/images/studio/image-card.png",
      detailsLink: "/studio-inner",
    },
    {
      id: 2,
      Title: "Studio Two",
      LocationText: "Warje, Pune",
      numberRate: "3.5",
      image: process.env.PUBLIC_URL + "/assets/images/studio/image-card.png",
      detailsLink: "/studio-inner",
      isPreBooked: true,
      isBooked: false,
    },
    {
      id: 3,
      Title: "Studio Three",
      LocationText: "Magarpatta, Hadapsar, Pune",
      numberRate: "5",
      image: process.env.PUBLIC_URL + "/assets/images/studio/image-card.png",
      detailsLink: "/studio-inner",
      isPreBooked: false,
      isBooked: true,
    },
    {
      id: 4,
      Title: "Studio Four",
      numberRate: "4",
      LocationText: "Viman Nagar, Pune",
      image: process.env.PUBLIC_URL + "/assets/images/studio/image-card.png",
      detailsLink: "/studio-inner",

    },
    {
      id: 4,
      Title: "Studio Four",
      numberRate: "4",
      LocationText: "Viman Nagar, Pune",
      image: process.env.PUBLIC_URL + "/assets/images/studio/image-card.png",
      detailsLink: "/studio-inner",

    },
    {
      id: 4,
      Title: "Studio Four",
      numberRate: "4",
      LocationText: "Viman Nagar, Pune",
      image: process.env.PUBLIC_URL + "/assets/images/studio/image-card.png",
      detailsLink: "/studio-inner",

    },
    {
      id: 4,
      Title: "Studio Four",
      numberRate: "4",
      LocationText: "Viman Nagar, Pune",
      image: process.env.PUBLIC_URL + "/assets/images/studio/image-card.png",
      detailsLink: "/studio-inner",

    },
    {
      id: 4,
      Title: "Studio Four",
      numberRate: "4",
      LocationText: "Viman Nagar, Pune",
      image: process.env.PUBLIC_URL + "/assets/images/studio/image-card.png",
      detailsLink: "/studio-inner",

    },
  ];

  return (
    <>
      <section className='media-card-sec pb-5'>
        <div className='container-lg mt-5'>
          <div className='background-card med-top'>
          <img className='curve-img' src={process.env.PUBLIC_URL + '/assets/images/about-us/banner/layer.png'}  />
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <div className='d-flex spacing'>
                  <div className='ms-auto'>
                    <Nav variant="pills" className="justify-content-center media-card-sec">
                      <Nav.Item>
                        <Nav.Link eventKey="first" data-hover="All"><span>All</span></Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="second" data-hover="Premium"><span>Premium</span></Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className='ms-auto'>
                    <div className='slider-icon ' onClick={() => setModalShow(true)}>
                      <FontAwesomeIcon icon={faSliders} className='slide-fa' />
                    </div>
                  </div>
                </div>

                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Row>
                      {MediacardsDetails.map((item, index) => (
                        <Col key={index} xxl={3} xl={3} lg={4} md={4} sm={6} >
                          <Link to={item.detailsLink || '#'}>
                            <StudioMediaCard
                              image={item.image}
                              LocationText={item.LocationText}
                              Title={item.Title}
                              numberRate={item.numberRate}
                              isPreBooked={item.isPreBooked}
                              isBooked={item.isBooked}
                            />
                          </Link>
                        </Col>
                      ))}
                      <Col lg={12} className='mx-auto'>
                        <div className='mt-5'>
                          <Pagination className='justify-content-center'>
                            <Pagination.Prev  >
                              <FontAwesomeIcon icon={faArrowLeft} className="page-link-prev" />
                            </Pagination.Prev>
                            <Pagination.Item>{1}</Pagination.Item>
                            <Pagination.Item>{2}</Pagination.Item>
                            <Pagination.Item>{3}</Pagination.Item>
                            <Pagination.Item>{4}</Pagination.Item>
                            <Pagination.Next  >
                              <FontAwesomeIcon icon={faArrowRight} className="page-link-next" />
                            </Pagination.Next>
                          </Pagination>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey="second">
                  <Row>
                      {MediacardsDetails.map((item, index) => (
                        <Col key={index} xxl={3} xl={3} lg={4} md={4} sm={6} >
                          <Link to={item.detailsLink || '#'}>
                            <StudioMediaCard
                              image={item.image}
                              LocationText={item.LocationText}
                              Title={item.Title}
                              numberRate={item.numberRate}
                              isPreBooked={item.isPreBooked}
                              isBooked={item.isBooked}
                            />
                          </Link>
                        </Col>
                      ))}
                      <Col lg={12} className='mx-auto'>
                        <div className='mt-5'>
                          <Pagination className='justify-content-center'>
                            <Pagination.Prev  >
                              <FontAwesomeIcon icon={faArrowLeft} className="page-link-prev" />
                            </Pagination.Prev>
                            <Pagination.Item className='active'>{1}</Pagination.Item>
                            <Pagination.Item>{2}</Pagination.Item>
                            <Pagination.Item>{3}</Pagination.Item>
                            <Pagination.Item>{4}</Pagination.Item>
                            <Pagination.Next  >
                              <FontAwesomeIcon icon={faArrowRight} className="page-link-next" />
                            </Pagination.Next>
                          </Pagination>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </section>
      <FilterModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default MediaCardSec;
