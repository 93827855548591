import { useState } from 'react';
import './Enquiry_form_modal.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Success_modal from '../Success_modal/Success_modal';

const Enquiry_form_modal = (props) => {
    const [phoneValue, setPhoneValue] = useState("");
    const [modalShow, setModalShow] = useState(false);

    const handleSubmit = () => {
        // Perform any necessary submission logic here

        // Close the current modal
        props.onHide();

        // Show the success modal
        setModalShow(true);
    };

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='Enquiry_form_modal'
            >
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-8 col-9 pe-0'>
                            <Form>
                                <div className='sec-1'>
                                    <Link to="/" onClick={props.onHide}><FontAwesomeIcon className='gomeicc' icon={faHouse} /></Link>
                                    <h6 className='enqh6'>Enquiry</h6>

                                    <Form.Group className='form-group'>
                                        <Form.Label>Enter Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Name" />
                                    </Form.Group>

                                    <Form.Group className='form-group'>
                                        <Form.Label>Enter Email</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Email" />
                                    </Form.Group>

                                    <Form.Group className='form-group'>
                                        <PhoneInput
                                            className="mb-3"
                                            country={"in"}
                                            value={phoneValue}
                                            onChange={setPhoneValue}
                                            placeholder='Enter Mobile Number '
                                            inputProps={{
                                                name: "phone",
                                                required: true,
                                                autoFocus: true,
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group className='form-group'>
                                        <Form.Label>Enter Message</Form.Label>
                                        {/* <Form.Control as="textarea" placeholder="Enter Message" /> */}
                                        <textarea class="form-control" placeholder="Enter Message" id="floatingTextarea"></textarea>
                                    </Form.Group>

                                    <Button className='submit-btn' onClick={handleSubmit}>Submit</Button>
                                </div>
                            </Form>
                        </div>
                        <div className='col-md-4 col-3 ps-0'>
                            <div className='sec-2'>
                                <img className='ban-bg' src={process.env.PUBLIC_URL + '/assets/images/homepage/inquiry/bg.png'} alt="background" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Success_modal
                maintext={"Enquiry Generated Successfully"}
                smalltext={""}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default Enquiry_form_modal;