import React from 'react'
import './studio-spaces.css'

const Studio_spaces = () => {
    return (
        <>
            <section className='Studio_spaces_sec'>
                <div className='container'>
                    <div className='title-div text-center'>
                        <h1 className='title mb-4'>Studio Spaces</h1>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='row'>
                                    <div className='col-6 mb-3'>
                                        <div className='ban-div'>
                                            <img className='std-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/studio-space/img1.png'} />
                                            <div className='overlay-text'>
                                                <p className='first-text'>The</p>
                                                <p className='first-text'>Media Space</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <div className='ban-div'>
                                            <img className='std-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/studio-space/img2.png'} />
                                            <div className='overlay-text'>
                                                <p className='first-text'>The Speak</p>
                                                <p className='first-text'>Easy Lounge</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <div className='ban-div'>
                                            <img className='std-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/studio-space/img2.png'} />
                                            <div className='overlay-text'>
                                                <p className='first-text'>The Speak</p>
                                                <p className='first-text'>Easy Lounge</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='col-6 mb-3'>
                                        <div className='ban-div'>
                                            <img className='std-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/studio-space/img3.png'} />
                                            <div className='overlay-text'>
                                                <p className='first-text'>Podcast</p>
                                                <p className='first-text'>Lounge</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 col-12 mx-auto'>
                                <div className='ban-div'>
                                    <img className='std-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/studio-space/img5.png'} />
                                    <div className='overlay-text'>
                                        <p className='first-text'>The</p>
                                        <p className='first-text'>Meetup</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Studio_spaces