import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "./contact.css";

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Success_modal from "../common-section/Modals/Success_modal/Success_modal";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const [modalShow, setModalShow] = useState(false);

  const [phoneValue, setPhoneValue] = useState("");

  function handleClick(el) {
    el.classList.toggle("float-label-top");
  }

  const [isFlipped, setIsFlipped] = useState(false);  
 

  const handleImageClick = () => {
    setIsFlipped(!isFlipped); // Toggle flip status
  };


  return (
    <>
      <section className="Contact_section">
        {/* <div className='overlay-bgg'></div> */}
        <div className="container ">
          <div className="row contmainpos">
            <div className="col-md-8">
              <div className="form-sec">
                <h1 className="conttitle">Contact Us</h1>

                <div className={`flip-card ${isFlipped ? "flipped" : "" || isFlipped ? "" : ""} `}>
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <Form.Group className="form-group">
                        <Form.Label>Enter Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your name"
                        />
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Enter Email</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your email"
                        />
                      </Form.Group>

                      <Form.Group className="form-group">
                        <PhoneInput
                          className="mb-3"
                          country={"in"}
                          placeholder="Enter mobile number"
                          value={phoneValue}
                          onChange={setPhoneValue}
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Type Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Type your message here"
                        />
                      </Form.Group>

                      <button
                        className="submit-btn"
                        onClick={() => setModalShow(true)}
                      >
                        Submit
                      </button>

                      <img
                        className="small-map-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/contact/small-map.png"
                        }
                        onClick={handleImageClick} // Click handler to toggle flip
                      />
                    </div>

                    <div className="flip-card-back">
                      <div className="map-section">
                        <div className="mapouter">
                          <div className="gmap_canvas">
                            <iframe
                              className="gmap_iframe"
                              frameborder="0"
                              scrolling="no"
                              marginheight="0"
                              marginwidth="0"
                              src="https://maps.google.com/maps?width=600&amp;height=800&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            ></iframe>
                            <a
                              href="https://pdflist.com/"
                              alt="pdflist.com"
                            ></a>
                          </div>
                        </div>

                        <img onClick={handleImageClick}  className="close-map-icon" src={process.env.PUBLIC_URL + '/assets/images/icons/closemap.png'} />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-4">
              
            </div> */}

            <div className="img-sec">
              <img className="banner-img"
                src={process.env.PUBLIC_URL + "/assets/images/contact/banner.png"} />
            </div>
          </div>



        </div>
      </section>

      <Success_modal
        maintext={"Message Sent Successfully"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Contact;
