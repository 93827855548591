import React, { useEffect } from 'react'
import './Studio-inner.css'
import Breadcrumb_comp from './Breadcrumb-comp/Breadcrumb_comp'
import Studio_card_one from './Studio-card/Studio_card_one'
import Studio_slider from './studio-slider/Studio_slider'
import Tabs_section from '../Studio_header/tabs-section/Tabs_section'

const Studio_inner = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);
    return (
        <>
            <div className='top-stud-bg'></div>

            <Breadcrumb_comp />
            <Studio_card_one />
            <Studio_slider />
            <Tabs_section />
        </>
    )
}

export default Studio_inner