import React from 'react'
import './Historycard_view_modal.css'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableCellsLarge } from '@fortawesome/free-solid-svg-icons';



const Historycard_view_modal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='Historycard_view_modal'
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className='d-flex  mt-2'>
                        <div className='icondiv'>
                            <FontAwesomeIcon icon={faTableCellsLarge} />
                        </div>
                        <div className='cont-div'>
                            <h6>Add Ons:</h6>
                            <p>Professional Assistant + ₹60/hr</p>
                            <p>Crew</p>
                        </div>
                    </div>

                    <div className='d-flex mt-2'>
                        <div className='icondiv'>
                            <FontAwesomeIcon icon={faTableCellsLarge} />
                        </div>
                        <div className='cont-div'>
                            <h6>Description:</h6>
                            <p>Lorem Ipsum Dolor Sit Amet Lorem Ipsum Dolor Sit Amet</p>
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default Historycard_view_modal