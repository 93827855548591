import { useEffect, useState } from "react";
import './loginform.css'
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const Login = () => {

    const [phoneValue, setPhoneValue] = useState("");

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    return (
        <>
            <section className='login-page-main-sec'>
                <div className='container'>
                    <div className='main-position-div'>
                        <div className='d-flex aligndiv'>
                            {/* dont integrate this form  */}
                            <div className='first-div slidein2 fadediv'>
                                <div className='loginformsec'>
                                    <FontAwesomeIcon className='home-icn' icon={faHouse} />
                                    <h1 className='login-title'>Sign Up</h1>

                                    <div className='form-div'>
                                        <Form >
                                            <Form.Group className='form-group'>
                                                <Form.Label>Enter Name</Form.Label>
                                                <Form.Control disabled type="text" placeholder="Enter Name" />
                                            </Form.Group>

                                            <Form.Group className='form-group'>
                                                <Form.Label>Enter Email</Form.Label>
                                                <Form.Control disabled type="text" placeholder="Enter Email" />
                                            </Form.Group>

                                            <Form.Group className='form-group'>
                                                <Form.Label>Enter Password</Form.Label>
                                                <Form.Control disabled type="text" placeholder="Enter Password" />
                                                <FontAwesomeIcon className='eye-icc' icon={faEye} />
                                            </Form.Group>

                                            <Form.Group className='form-group'>
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control disabled type="text" placeholder="Confirm Password" />
                                                <FontAwesomeIcon className='eye-icc' icon={faEye} />
                                            </Form.Group>

                                            <Form.Group className='form-group'>
                                                <PhoneInput
                                                    disabled
                                                    className="mb-3"
                                                    country={"in"}
                                                    value={phoneValue}
                                                    onChange={setPhoneValue}
                                                    inputProps={{
                                                        name: "phone",
                                                        required: true,
                                                        autoFocus: true,
                                                    }}
                                                />
                                            </Form.Group>



                                            <button disabled className='Login-btn'>Create Account</button>
                                        </Form>
                                    </div>

                                    <p className='lastcont' >Already have an account?<Link to='/login' disabled>Login</Link></p>


                                    <Form>
                                        {['checkbox'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                                <Form.Check
                                                    inline
                                                    label="By signing up, I agree to the Terms and Conditions"
                                                    name="group1"
                                                    type={type}
                                                    id={`inline-${type}-1`}
                                                />
                                            </div>
                                        ))}
                                    </Form>
                                </div>
                            </div>
                            {/* dont integrate this form  */}

                            <div className='mid-img-div'>
                                <img src={process.env.PUBLIC_URL + '/assets/images/login/mid-img.png'} />
                            </div>
                            {/* this is login form  */}
                            <div className='last-div slidein margin-top'>
                                <div className='loginformsec'>
                                    <div className="welcometitle">
                                        <h1>Welcome to <br></br>
                                            Flicknshoot!
                                        </h1>
                                    </div>

                                    <Link to="/"><FontAwesomeIcon className='home-icn' icon={faHouse} /></Link>
                                    <h1 className='login-title'>Log In</h1>
                                    <p className='title-sub'>to access your dashboard & manage bookings securely.</p>

                                    <div className='form-div'>
                                        <Form >
                                            <Form.Group className='form-group'>
                                                <Form.Label>Email or Mobile Number</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Email or Mobile Number" />
                                            </Form.Group>
                                            <Form.Group className='form-group'>
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control type={
                                                    confirmPasswordVisible
                                                        ? "text"
                                                        : "password"
                                                } placeholder="Enter Password" />

                                                <div
                                                    className="eye-icc"
                                                    onClick={togglePasswordVisibility}
                                                >
                                                    <FontAwesomeIcon
                                                        className="eye-icc"
                                                        icon={
                                                            passwordVisible ? faEye : faEyeSlash
                                                        }
                                                    />
                                                </div>
                                            </Form.Group>

                                            <Form.Group className='form-group text-end'>
                                                <Link className='forgo-pass' to="/forgot-password">Forgot Password?</Link>
                                            </Form.Group>

                                            <Link to="/"><button className='Login-btn'>Log In</button></Link>
                                        </Form>
                                    </div>

                                    <p className='lastcont'><Link to="/sign-up">New here?</Link>Sign up for free to unlock exclusive discounts,
                                        faster bookings, and join a vibrant community of creatives. </p>


                                    <div className='d-flex'>
                                        <div className='my-auto'>
                                            <p className='loginby'>You can also login by using your</p>
                                        </div>
                                        <Link to="/"><img className='socialicon' src={process.env.PUBLIC_URL + '/assets/images/icons/google.png'} /></Link>

                                        <Link to="/"><img className='socialicon' src={process.env.PUBLIC_URL + '/assets/images/icons/twitter.png'} /></Link>

                                        <Link to="/"><img className='socialicon' src={process.env.PUBLIC_URL + '/assets/images/icons/facebook.png'} /></Link>
                                    </div>
                                </div>
                            </div>
                            {/* this is login form  */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login