import React, { useRef, useState } from 'react';
import './Studio_big_slider_modal.css'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Studio_big_slider_modal = (props) => {

    const [swiperInstance, setSwiperInstance] = useState(null);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    return (
        <>
            <Modal
                {...props}
                // size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='Studio_big_slider_modal'

            >
                {/* <Modal.Header closeButton> */}
                {/* <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title> */}
                {/* </Modal.Header> */}
                <Modal.Body>

                    <Swiper
                        slidesPerView={3}
                        spaceBetween={50}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: false,
                        }}
                        navigation={false}
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                        modules={[Autoplay, 
                            // Pagination, 
                            Navigation]}
                    >
                        <SwiperSlide>
                            <div className='slider-main'>
                                <img className='slider-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/big-slider.png'} />
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='slider-main'>
                                <img className='slider-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/big-slider.png'} />
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='slider-main'>
                                <img className='slider-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/big-slider.png'} />
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='slider-main'>
                                <img className='slider-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/big-slider.png'} />
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='slider-main'>
                                <img className='slider-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/big-slider.png'} />
                            </div>
                        </SwiperSlide>



                        <div class="silder-btn">
                            <div
                                className="back-btn"
                                onClick={() => handleMainSliderClick("prev")}
                            ></div>
                            <div
                                className="next-btn"
                                onClick={() => handleMainSliderClick("next")}
                            ></div>
                        </div>
                    </Swiper>

                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer> */}



            </Modal>
        </>
    )
}

export default Studio_big_slider_modal