import React, { useEffect } from 'react'
import MediaBanner from './MediaBanner/MediaBanner'
import MediaCardSec from './MediaCardSec/MediaCardSec'
import Home_inquiry from '../home/inquiry/Home_inquiry'

const MediaSpaces = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);
    return (
        <>
            <MediaBanner />
            <MediaCardSec />
            <Home_inquiry />
        </>
    )
}

export default MediaSpaces
